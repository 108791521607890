<div class="flex-v-center top-nav p-3">
  <div class="d-flex ms-2 me-4 mt-1">
    <ng-content></ng-content>
  </div>
  <div class="d-flex flex-grow-1">
    <span (click)="adminMenu.toggle($event)" *ngIf="authService.isInRole('admin') | async" class="mx-3 regular-nav-link"
    >Admin</span>
    <!-- (click)="masterMenu.toggle($event)" -->
    <span [routerLink]="['/master']" class="mx-3 regular-nav-link">Masterdata</span>

    <span *ngIf="authService.isInRole('approver') | async" [routerLink]="['/queue/approver']"
          class="mx-3 regular-nav-link"
    >Approvals</span>
    <span *ngIf="authService.isInRole('checker') | async" [routerLink]="['/queue/checker']"
          class="mx-3 regular-nav-link"
    >Checks</span>
    <span *ngIf="authService.isInRole('approver') | async" [routerLink]="['/delegation']"
          class="mx-3 regular-nav-link"
    >Delegations</span>
    <!-- <span *ngIf="authService.isInRole('user') | async" [routerLink]="['/queue/user']"
          class="mx-3 regular-nav-link"
    >Expenses</span> -->
    <span *ngIf="authService.isInRole('user') | async" [routerLink]="['/invoice']"
          class="mx-3 regular-nav-link"
    >Invoices</span>
    <span [routerLink]="['/purchase-requisition']"
          class="mx-3 regular-nav-link">Purchase requisition</span>

    <span  [routerLink]="['/purchase-order']"
    class="mx-3 regular-nav-link">
    Purchase order</span>
    <span (click)="budgetMenu.toggle($event)" class="mx-3 regular-nav-link">Budget</span>

    <span [routerLink]="['/procurement']" class="mx-3 regular-nav-link">Procurement</span>

    <span *ngIf="authService.isInRole('report') | async" [routerLink]="['/reports']"
          class="mx-3 regular-nav-link"
    >Reports</span>
  </div>
  <div class="flex-v-center me-2">
    <tg-square-button
      (click)="menu.toggle($event)"
      backgroundColorHex="#116fd6"
      [displayText]="username"
      foregroundColorHex="#eee"
      class="pointer"
    >
    </tg-square-button>
  </div>
</div>

<p-menu #menu [model]="items" [popup]="true"></p-menu>


<p-tieredMenu #adminMenu [model]="adminMenuItems" [popup]="true"></p-tieredMenu>

<p-tieredMenu #budgetMenu [model]="budgetMenuItems" [popup]="true"></p-tieredMenu>

<!-- <p-tieredMenu #masterMenu [model]="masterMenuItems" [popup]="true"></p-tieredMenu> -->


