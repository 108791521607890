import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class BaseInterceptorService implements HttpInterceptor {
  constructor(
    private toastrService: ToastrService,
    private authService: AuthService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const access_token = sessionStorage.getItem('pxp_token');
    const reqOut = !!access_token
      ? req.clone({
        setHeaders: {
          Authorization: `Bearer ${sessionStorage.getItem('pxp_token')}`,
        },
      })
      : req;
    return next.handle(reqOut).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status >= 400 && err.status < 500 && !reqOut.headers.has('Skip-Error-Handling')) {
            if (!!err.error?.message) {
              this.toastrService.error(
                err.error?.message,
                `Error: ${err.status}`
              );
            }

            if (err.status == 401) {
              this.authService.logout();
            }

            // this.toastrService.add({
            //   severity: 'error',
            //   summary: 'Please check the data entered',
            //   detail: err.error?.message,
            //   life: 2000,
            // });
          }
        }

        //  We need to return the new empty response
        //  even when we don't have one (because of error in communication)
        return throwError(err);
      })
    );
  }
}
