import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { RestService } from '../../_services/rest.service';
import {
  EmployeeDashboardViewModel,
  LookupDetailsViewModel,
  QueueFilterViewModel,
  ServerResponseWithBody,
} from '../../_models/app.models';
import { getUrlPathFragment } from '../../_static/util';
import { Observable, forkJoin, map, tap } from 'rxjs';
import { BudgetDetailsResponse, BudgetDetailsViewModel, DocumentDataRequestBody, DocumentDataResponseBody, GetDashboardConfiguration, SaveDasboardConfig, StatsDataResponseBody, StatsDetails, WidgetIdsConstant } from './dashboard.model';
import { IPRLookupDetailsResponse } from 'src/app/_models/pr.models';
import { MONTHSLIST } from 'src/app/_static/months';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { QueueFilterService } from 'src/app/_services/_shared_service/queue-filter.service';
@Component({
  selector: 'tg-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent implements OnInit {
  viewModel?: EmployeeDashboardViewModel;
  pastMonthsData: any;
  chartConfiguration: any;
  selectedMonth: number | null | undefined;
  selectedYear!: string;
  prQueue!: DocumentDataResponseBody
  poQueue!: DocumentDataResponseBody
  invoiceQueue!: DocumentDataResponseBody
  budegtDetails!: BudgetDetailsResponse;
  budgetDetailsTableData!: BudgetDetailsViewModel;
  financialYearList: IPRLookupDetailsResponse[] = [];
  monthsList: LookupDetailsViewModel[] = MONTHSLIST;
  dashboardConfig!: GetDashboardConfiguration[];
  documentRequest!: DocumentDataRequestBody;
  menuVisible: boolean = false;
  draggedItem: any;
  displayMenu: boolean = false;
  widgetBody!: GetDashboardConfiguration[];
  hasChanges: boolean = false;
  sortedWidgets: any;
  widgetData: any = {};
  filterParams!: QueueFilterViewModel;
  page: number = 1;
  pageSize: number = 5;
  totalRecords: number = 0;
  vendorCountTitle = 'Total Vendor Count';
  invoiceCountTitle = 'Total Invoice Count';
  taxableAmountTitle = 'Total Taxable Amount';
  invoiceAmountTitle = 'Total Invoice Amount';
  taxAmountTitle = 'Total Tax Amount';
  vendorCount = '1760';
  invoiceCount = '3509';
  taxableAmount = '2509755';
  invoiceAmount = '3525562';
  taxAmount = '3609985';
  vendorCountInfo = 'New Unique Vendors';
  additionalInfo = 'for last month';
  iconVendorCount = 'bi bi-people-fill';
  iconInvoiceCount = 'bi bi-files';
  iconTaxableAmount = 'bi bi-people-fill';
  iconInvoiceAmount = 'bi bi-people-fill';
  iconTaxAmount = 'bi bi-receipt';
  statsData: StatsDetails = {

    "totalVendorCount": 34,

    "totalVendorInsight": "25",

    "totalInvoiceCount": 10,

    "totalInvoiceInsight": "",

    "totalTaxableAmount": 0.0,

    "totalTaxableAmountInsight": "",

    "totalInvoiceAmount": 402935.20,

    "totalInvoiceAmountInsight": "",

    "totalTaxAmount": 0.0,

    "totalTaxAmountInsight": "0.27%"

  }
  constructor(private restService: RestService, private elRef: ElementRef, private router: Router,
    private _queueFilterService: QueueFilterService
  ) {
    this.getFinancialYearListAPICall().subscribe((res: IPRLookupDetailsResponse[]) => {
      this.financialYearList = res
    })
  }
  ngOnInit(): void {
    this.filterParams = this.initPage();
    this.loadData();

  }
  initPage(): QueueFilterViewModel {
    return <QueueFilterViewModel>{
      page: 1,
      pageSize: 5,
      searchString: "",
      searchCriteria: <Record<string, string[]>>{},
      sortByColumnName: null,
      sortOrder: 1,
    }
  }
  getNextPage(event: any): void {
    this.filterParams.page = event.first / event.rows + 1;
    this.filterParams.pageSize = event.rows;
    this.filterParams.sortByColumnName = event.sortField === undefined ? null : event.sortField;
    this.filterParams.sortOrder = event.sortOrder;
    this.pageSize = event.rows;
    this.restService
      .post<QueueFilterViewModel,
        ServerResponseWithBody<BudgetDetailsResponse>>(
          getUrlPathFragment('dashboards', 'widget', 'budget-details'),
          this.filterParams
        )
      .subscribe(data => {
        this.budegtDetails = data.body;
        this.widgetData = {
          1: this.formatData(1, this.invoiceQueue),
          2: this.formatData(2, this.prQueue),
          3: this.formatData(3, this.poQueue),
          4: this.formatData(4, this.budegtDetails)
        };
      }
      );

  }
  getPurchaseRequest(): Observable<any> {
    this.documentRequest = { financialYear: this.selectedYear || '', month: this.selectedMonth || null, name: 'Purchase Request' }
    return this.restService.post
      <DocumentDataRequestBody, ServerResponseWithBody<DocumentDataResponseBody>>
      (getUrlPathFragment('dashboards', 'widget', WidgetIdsConstant.PURCHASE_REQUEST), this.documentRequest)
      .pipe(
        tap(data => this.prQueue = data.body)
      );
  }
  getInvoiceDetails(): Observable<any> {
    this.documentRequest = { financialYear: this.selectedYear || '', month: this.selectedMonth || null, name: 'Invoice' }
    return this.restService.post
      <DocumentDataRequestBody, ServerResponseWithBody<DocumentDataResponseBody>>
      (getUrlPathFragment('dashboards', 'widget', WidgetIdsConstant.INVOICE), this.documentRequest)
      .pipe(
        tap(data => this.invoiceQueue = data.body)
      );
  }
  getPurchaseOrder(): Observable<any> {
    this.documentRequest = { financialYear: this.selectedYear || '', month: this.selectedMonth || null, name: 'Purchase Order' }
    return this.restService.post
      <DocumentDataRequestBody, ServerResponseWithBody<DocumentDataResponseBody>>
      (getUrlPathFragment('dashboards', 'widget', WidgetIdsConstant.PURCHASE_ORDER), this.documentRequest)
      .pipe(
        tap(data => this.poQueue = data.body)
      );
  }
  getFinancialYearListAPICall(): Observable<IPRLookupDetailsResponse[]> {
    return this.restService.read<ServerResponseWithBody<IPRLookupDetailsResponse[]>>(
      (getUrlPathFragment('lookup', 'FinancialYear'))
    ).pipe(map(res => res.body));
  }
  getBudgetDetails(): Observable<any> {
    return this.restService
      .post<QueueFilterViewModel,
        ServerResponseWithBody<BudgetDetailsResponse>>(
          getUrlPathFragment('dashboards', 'widget', 'budget-details'),
          this.filterParams
        )
      .pipe(
        tap(data => this.budegtDetails = data.body)
      );
  }
  loadData(): void {
    this.restService
      .read<ServerResponseWithBody<EmployeeDashboardViewModel>>(
        getUrlPathFragment('dashboard')
      )
      .pipe(map((v) => v.body))
      .subscribe((v) => {
        this.viewModel = v;
      });
    this.getStatsData()
    this.getDashboardConfig();


  }
  getStatsData() {
    this.documentRequest = { financialYear: this.selectedYear || '', month: this.selectedMonth || null, name: '' }
    return this.restService.post
      <DocumentDataRequestBody, ServerResponseWithBody<StatsDataResponseBody>>
      (getUrlPathFragment('dashboards', 'widget', 'stats', 'invoice'), this.documentRequest)
      .pipe(map((v) => v.body)).
      subscribe((data) => {
        this.statsData = data.data;
      })
  }
  formatData(widgetId: number, apiData: any) {
    const nameMap: { [key: string]: string } = {
      'Purchase Request': 'PR',
      'Purchase Order': 'PO',
      'Invoice': 'Invoice',
      'Budget Details': 'Budget Details'
    };

    const widgetName = this.dashboardConfig.find(w => w.widgetId === widgetId)?.name || 'Unknown';

    return {
      name: nameMap[widgetName] || widgetName,
      data: apiData
    };
  }
  getWidgetComponent(widget: any) {
    switch (widget.widgetId) {
      case 1:
        return 'tg-document-detail-count-widget';
      case 2:
        return 'tg-document-detail-count-widget';
      case 3:
        return 'tg-document-detail-count-widget';
      case 4:
        return 'tg-budget-details-widget'; // Adjust this if needed
      // Add more cases for different widget types
      default:
        return '';
    }
  }

  getWidgetData(widgetId: number) {
    return this.widgetData[widgetId] || {};
  }
  getDashboardConfig() {
    this.restService.read<ServerResponseWithBody<GetDashboardConfiguration[]>>(
      getUrlPathFragment('dashboards', 'configurations')
    )
      .pipe(map((v) => v.body))
      .subscribe((v) => {
        this.dashboardConfig = v;
        this.sortedWidgets = this.dashboardConfig.sort((a, b) => a.sequenceId - b.sequenceId);
        this.widgetBody = [...this.sortedWidgets]
        forkJoin([
          this.getInvoiceDetails(),
          this.getPurchaseOrder(),
          this.getPurchaseRequest(),
          this.getBudgetDetails()
        ]).subscribe(() => {
          // After all data has been fetched and the observables have completed
          this.widgetData = {
            1: this.formatData(1, this.invoiceQueue),
            2: this.formatData(2, this.prQueue),
            3: this.formatData(3, this.poQueue),
            4: this.formatData(4, this.budegtDetails)
          };
        });
      })
  }
  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.widgetBody, event.previousIndex, event.currentIndex);
    this.updateSequenceIds();
    this.checkForChanges();

  }

  updateSequenceIds() {
    this.widgetBody.forEach((item, index) => {
      item.sequenceId = index + 1;
    });
  }
  onSwitchChange(item: any) {
    this.hasChanges = true

  }
  saveChanges() {
    // Save logic here
    this.widgetBody.forEach(newWidget => {
      const existingWidgetIndex = this.dashboardConfig.findIndex(widget => widget.id === newWidget.id);

      if (existingWidgetIndex !== -1) {
        // Update existing widget with the new data
        this.dashboardConfig[existingWidgetIndex] = { ...this.dashboardConfig[existingWidgetIndex], ...newWidget };
      } else {
        // Add new widget if it's not already in the dashboardConfig
        this.dashboardConfig.push(newWidget);
      }
    });
    const config = { configs: this.dashboardConfig }
    this.restService.post
      <SaveDasboardConfig, ServerResponseWithBody<any>>
      (getUrlPathFragment('dashboards', 'configurations'), config)
      .pipe(map(res => res.body)).subscribe(
        (response: any) => {
          // Optionally, clear the file list and reset totalSize
          this.getDashboardConfig();

        },
        (error) => {

        }
      )
    this.menuVisible = false
    this.hasChanges = false;
  }

  resetChanges() {
    // Reset to original data
    this.widgetBody = [...this.dashboardConfig];
    this.updateSequenceIds();
    this.hasChanges = false;
  }

  checkForChanges() {
    this.hasChanges = JSON.stringify(this.widgetBody) !== JSON.stringify(this.dashboardConfig);
  }
  onClose() {
    this.menuVisible = false
  }
  onFinancialYearChange() {
    this.selectedMonth = null
    this.getStatsData()
    this.getDashboardConfig();

  }
  onMonthChange() {
    this.getStatsData()
    this.getDashboardConfig();
  }
  navigateToDocument(status: string, name: string) {
    if (name == "Purchase Request") {
      this.router.navigate(['/purchase-requisition'], { state: { status } });
    }
    if (name == "Purchase Order") {
      this.router.navigate(['/purchase-order'], { state: { status } });
    }
    if (name == "Invoice") {
      this.router.navigate(['/invoice'], { state: { status } });
    }
    // this.router.navigate(['/purchase-requisition'], { state: { status } });
  }
  getSearchValue(value: string) {

    this.filterParams.searchCriteria = { 'budgetCode': [value] };
    this.restService
      .post<QueueFilterViewModel,
        ServerResponseWithBody<BudgetDetailsResponse>>(
          getUrlPathFragment('dashboards', 'widget', 'budget-details'),
          this.filterParams
        )
      .subscribe(data => {
        this.budegtDetails = data.body;
        this.widgetData = {
          1: this.formatData(1, this.invoiceQueue),
          2: this.formatData(2, this.prQueue),
          3: this.formatData(3, this.poQueue),
          4: this.formatData(4, this.budegtDetails)
        };
      }
      );
  }
}
