import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tg-statistics-widget',
  templateUrl: './statistics-widget.component.html',
  styleUrls: ['./statistics-widget.component.scss']
})
export class StatisticsWidgetComponent implements OnInit {
  @Input() title: string = '';
  @Input() count: number = 0;
  @Input() additionalInfo: string = '';
  @Input() icon: string = ''; // Icon class or URL
  @Input() currency: boolean = false;
  @Input() color!: string;
  @Input() insight: string = ''
  constructor() { }

  ngOnInit(): void {
  }

}
