import { Injectable } from '@angular/core';
import { IApprovalKeyValuePairsViewModel } from '../_models/app.models';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {
    // This is intentional
  }

  getInitialLetters(input: string, length = 2): string {
    if (input) {
      const words = input.split(' ');
      let initials = '';
      words.forEach((w) => {
        initials += w.charAt(0).toUpperCase();
        if (initials.length === length) {
          return;
        }
      });
      return initials;
    } else {
      return '';
    }
  }

  downloadReport(reportData: any, reportType: any) {
    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
    const extension = '.csv';
    const window = this.getWindow();
    const blobFile = new Blob(
      [this.base64ToArrayBuffer(reportData.encoded, window)],
      { type: type }
    );
    const fileURL = URL.createObjectURL(blobFile);
    let anchor = document.createElement("a");
    anchor.download = `${reportType}${extension}`;
    anchor.href = fileURL;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  openPdf(reportData: any) {
    const type = 'application/pdf';
    const extension = '.pdf';
    const window = this.getWindow();
    const blobFile = new Blob(
      [this.base64ToArrayBuffer(reportData.encoded, window)],
      { type: type }
    );
    const fileURL = URL.createObjectURL(blobFile);
    const filename = 'report';
    const packReportWindow = window.open();
    packReportWindow!.document.write(
      `<iframe src=${fileURL} style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
    packReportWindow!.history.pushState('', '', `${filename}${extension}`);
    packReportWindow!.document.write(
      `<title>${filename}</title>`
    );
    packReportWindow!.document.close();
  }

  openHtml(reportData: any, reportType: any) {
    const type = 'text/html';
    const extension = '.html';
    const window = this.getWindow();
    const blobFile = new Blob(
      [this.base64ToArrayBuffer(reportData.encoded, window)],
      { type: type }
    );
    const fileURL = URL.createObjectURL(blobFile);
    const filename = `${reportType}`;
    const packReportWindow = window.open();
    packReportWindow!.document.write(
      `<iframe src=${fileURL} style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
    packReportWindow!.history.pushState('', '', `${filename}${extension}`);
    packReportWindow!.document.write(
      `<title>${filename}</title>`
    );
    packReportWindow!.document.close();
  }

  getWindow(): Window {
    return window;
  }

  base64ToArrayBuffer(base64Data: any, window: Window) {
    const binaryString = window.atob(base64Data);
    const binaryStringLength = binaryString.length;
    const bytes = new Uint8Array(binaryStringLength);
    for (let i = 0; i < binaryStringLength; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  getEntityDropDownList(env : string) : string[] {
    let result = ['ALL'];
    let entitySpecificDropdown: string[] = [];
    switch (env) {
      case "ABFL" :
        entitySpecificDropdown = ['ABFL', 'ABMI', 'ABIF'];
        break;
      case "ABHFL" :
        entitySpecificDropdown = ['ABHFL'];
        break;
      case "ABML" :
        entitySpecificDropdown = ['ABML'];
        break;
      case "ABSLPM" :
        entitySpecificDropdown = ['ABSLPM'];
        break;
      default:
        // if the entity is not specificed only provide 'ALL' option
        break;
    }
    // If there is only a single entity in the drop down, then remove the 'ALL' option
    if (entitySpecificDropdown.length == 1) {
      return entitySpecificDropdown;
    }
    return result.concat(entitySpecificDropdown);
  }

  round(value : number, decimalPlaces : number) {
    return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  }

  populateKeyValuePairs(data: { [key: string]: string }) {
    const viewModel: { [key: string]: string | null } = {};
    let counter = 1;

    for (const [key, value] of Object.entries(data)) {
      const formattedKey = counter < 10 ? `key0${counter}` : `key${counter}`;
      const formattedValue = counter < 10 ? `value0${counter}` : `value${counter}`;

      if (value?.trim() ?? null) {
        viewModel[formattedValue] = value?.trim() ?? null;
        viewModel[formattedKey] = key;
      }

      counter++;
    }
  
    return viewModel;
  }
}
